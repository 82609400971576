import React, { useCallback, useState } from 'react';

import * as SC from './AiPadHeader.styled';
import { MODE_ITEMS } from './config';
import useAiStore from '../../../Hooks/useAiStore';
import { Dropdown } from '../../Dropdown';
import { ArrowLeftIcon, ArrowRightIcon } from '../../Icons';
import { IconKnob, KnobAppearance, KnobSize } from '../../Knob';
import { AiPadSettings } from '../AiPadSettings';

type AiPadHeaderProps = {};

const AiPadHeader: React.FC<AiPadHeaderProps> = () => {
    const [isSettingsVisible, setIsSettingsVisible] = useState(false);

    const isSingleScreenMode = useAiStore((store) => store.isSingleScreenMode);
    const isSingleScreenMobileMode = useAiStore((store) => store.isSingleScreenMobileMode);
    const isSingleScreenFullMode = useAiStore((store) => store.isSingleScreenFullMode);
    const segments = useAiStore((store) => store.segments);
    const [paraphrasingMode, setParaphrasingMode] = useAiStore((store) => [store.paraphrasingMode, store.setParaphrasingMode]);

    const handleModeSelect = useCallback((value) => {
        setParaphrasingMode(value);
    }, [setParaphrasingMode]);

    const handleBackClick = useCallback(() => {
        useAiStore.setState({ isSingleScreenFullMode: false });
    }, []);

    const handleForwardClick = useCallback(() => {
        useAiStore.setState({ isSingleScreenResultMode: true });
    }, []);

    const handleSettingsClick: React.MouseEventHandler<HTMLAnchorElement> = useCallback((event) => {
        event.preventDefault();
        event.stopPropagation();

        setIsSettingsVisible(true);
    }, []);


    const handleSettingsClose = useCallback(() => {
        setIsSettingsVisible(false);
    }, []);

    if (isSingleScreenMobileMode && !isSingleScreenFullMode) {
        return null;
    }

    return <SC.Wrapper>
        {isSingleScreenMode && <div>
            {isSingleScreenMobileMode && <IconKnob
                onClick={handleBackClick}
                appearance={KnobAppearance.Secondary}
                size={KnobSize.Large}
                icon={<ArrowLeftIcon />}
            />}
            {!!segments.length && (
                <IconKnob
                    onClick={handleForwardClick}
                    appearance={KnobAppearance.Secondary}
                    size={KnobSize.Large}
                    icon={<ArrowRightIcon />}
                />
            )}
        </div>}
        <Dropdown
            header="Schreibstil"
            title="Schreibstil auswählen"
            useMobileHeaderAsDefault
            items={MODE_ITEMS}
            activeValue={paraphrasingMode}
            handleClick={isSingleScreenMobileMode ? handleSettingsClick : undefined}
            handleSelect={handleModeSelect}
            alignPanel={isSingleScreenMode ? 'right' : 'left'}
            hideArrow={isSingleScreenMobileMode}
        />
        {isSettingsVisible && <AiPadSettings handleClose={handleSettingsClose} />}
    </SC.Wrapper>;
};

export default AiPadHeader;
