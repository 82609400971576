import React, { useCallback } from 'react';

import * as SC from './AiGeneralMessage.styled';
import useAiStore, { MESSAGE_CODE } from '../../../Hooks/useAiStore';
import { DocumentIdentifier } from '../../../Util/DocumentIdentifier';
import { formatToThousandSeparator } from '../../../Util/formatingUtils';
import { PlatformTypes } from '../../../Util/PlatformUtil';
import AiMessage, { MESSAGE_APPEARANCE } from '../AiMessage';

type AiGeneralMessageProps = {};

const AiGeneralMessage: React.FC<AiGeneralMessageProps> = () => {
    const generalError = useAiStore(store => store.error);
    const charactersLimit = useAiStore(store => store.summary.charactersLimit);
    const isLightPlatform = DocumentIdentifier.getPlatformIdentifier() === PlatformTypes.lite;

    let showError = !!(generalError && Object.keys(generalError).length);

    const handleClose = useCallback(() => {
        useAiStore.setState({ error: null });
    }, []);

    if (!showError) {
        return null;
    }

    let { title, content, code, appearance = MESSAGE_APPEARANCE.General } = generalError!;

    if (code === MESSAGE_CODE.Common) {
        title = 'Anfrage nicht erfolgreich';
        content = 'Ihre Anfrage konnte leider nicht verarbeitet werden. Bitte versuchen Sie es erneut.';
    }

    if (code === MESSAGE_CODE.LongText) {
        title = 'Keine Optimierung möglich';
        content = `Der eingegebene Text darf maximal ${formatToThousandSeparator(charactersLimit ?? 0, '\u00A0')} Zeichen lang sein. Bitte kürzen Sie ihn entsprechend.`;
    }

    if (code === MESSAGE_CODE.ZeroChanges) {
        title = 'Alles korrekt';
        content = `Es wurden keine Fehler gefunden.`;
        appearance = MESSAGE_APPEARANCE.Success;
    }

    showError = !!(title || content);

    if (!showError) {
        return null;
    }

    return <SC.Wrapper isLightPlatform={isLightPlatform}>
        <AiMessage
            title={title}
            appearance={appearance}
            handleClose={handleClose}
        >
            {content}
        </AiMessage>
    </SC.Wrapper>;
};

export default AiGeneralMessage;
